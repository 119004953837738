import { RegistrationStatus, type Client } from '~/models/clients';

const ROUTES_TO_AUTHENTICATE = ['account', 'dashboard'] as const;

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) {
    return;
  }

  const {
    // using $localePath instead of useLocalePath will not result in warning:
    // Calling useRoute within middleware may lead to misleading results
    $localePath: localePath,
  } = useNuxtApp();
  const authStore = useAuthStore();
  const layoutStore = useLayoutStore();
  const questionnairesStore = useQuestionnairesStore();

  const targetName = to.name as string | undefined | null;
  const shouldAuthenticate = ROUTES_TO_AUTHENTICATE.some((route) => targetName?.includes(route));

  if (shouldAuthenticate) {
    if (!authStore.isLoggedIn || !!authStore.user) {
      await authStore.hydrate();

      if (!authStore.isLoggedIn) {
        layoutStore.setAfterLoginPage({ page: to });

        return await navigateTo(localePath('login'));
      }
    }

    const fromName = from.name as string | null | undefined;
    if (
      !to.redirectedFrom &&
      !(targetName?.includes('account-') && fromName?.includes('account-'))
    ) {
      await questionnairesStore.getAll();
      if (
        !questionnairesStore.latestQuestionnaire ||
        (questionnairesStore.latestQuestionnaire &&
          (!questionnairesStore.latestQuestionnaire.IsCompleted ||
            (questionnairesStore.latestQuestionnaire.IsCompleted &&
              questionnairesStore.latestQuestionnaire.Blocked)))
      ) {
        return await navigateTo(localePath('account-questionnaire'));
      }

      if (
        authStore.user.RegistrationStatus === RegistrationStatus.InProgress ||
        authStore.user.RegistrationStatus === RegistrationStatus.ClientResponseNeeded
      ) {
        if (shouldRedirectToContractSigning(authStore.user)) {
          return await navigateTo(localePath('account-contract-signing'));
        }
      }
    }
  }
});

function shouldRedirectToContractSigning(user: Client) {
  return user.RegistrationStatus > RegistrationStatus.InProgress && !user.IsAgreedWithTerms;
}
